import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PagesHero from '../components/PagesHero';
import ServiceWorks from '../components/ServiceWorks';
import FAQ from '../components/FAQ';
import Border from '../components/Border';

function AboutUs({ location }) {
  useEffect(() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo('faq', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  });

  return (
    <Layout location={location}>
      <SEO title="About us | FAQ's" />
      <PagesHero aboutUs location={location} />
      <Border />
      <ServiceWorks location={location} />
      <Border />
      <FAQ location={location} />
    </Layout>
  );
}


AboutUs.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default AboutUs;
