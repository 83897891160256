import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './styles.scss';
import save from './images/save.jpg';
import select from './images/content.jpg';
import subscribe from './images/subscribe.jpg';

const ServiceWorks = ({ location }) => (
  <section className="service-works">
    <div className="container">
      <div className="service-works__wrapper">
        <div className="service-works__product">
          <div className="service-works__image">
            <img src={subscribe} alt="Subscribe" />
          </div>
          <div className="service-works__text">
            <h3 className="service-works__heading">
              Email Notifications
            </h3>
            <p className="service-works__para">
              Subscribe now to receive email notifications tailored to your preferences
              – update your settings at any time to see more of what matters to you.
            </p>
          </div>
        </div>

        <div className="service-works__product">
          <div className="service-works__image">
            <img src={select} alt="Choose Content" />
          </div>
          <div className="service-works__text">
            <h3 className="service-works__heading">
              Choose Your Preferences
            </h3>
            <p className="service-works__para">
              Our services can help you find the right deals at the right time.
              Select your preferred financial products and begin to explore offers that
              could save you money.
            </p>
          </div>
        </div>

        <div className="service-works__product">
          <div className="service-works__image">
            <img src={save} alt="Save Money" />
          </div>
          <div className="service-works__text">
            <h3 className="service-works__heading">
              Subscribe & Save
            </h3>
            <p className="service-works__para">
              Our refreshingly straightforward no-obligation service is designed to put you in
              control of the news and offers you receive. Subscribe to see how much you could save.
            </p>
          </div>
        </div>
      </div>
      <Link to={`/sign-up/${location.search}`} className="mainButton service-works__cta">
        Sign up now &gt;
      </Link>
    </div>
  </section>
);

ServiceWorks.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default ServiceWorks;
