import React from 'react';

import './styles.scss';
import mmb from './mmb-arrow.svg';

const FAQ = () => (
  <section className="faq" id="faq">
    <div className="container faq__container">
      <div className="faq__content">
        <div className="faq__header-wrapper">
          <h3 className="faq__header">
            Your Questions Answered
          </h3>
        </div>
        <div className="faq__content-wrapper">
          <img className="faq__mmb-arrow" src={mmb} alt="MMB Arrow" />
          <div>
            <h2 className="faq__heading">
              Who is My Money Back?
            </h2>
            <p className="faq__para">
              Unlike time-consuming comparison sites that deliver limited results for a
              single service, we match your preferences to a broad range of financial products.
            </p>
            <p className="faq__para">
              Our no-obligation subscription service is based on your preferences – we’ll only
              contact you with news and offers that intertest you. Update your preferences at
              any time to tailor your notifications.
            </p>
          </div>
        </div>
        <div className="faq__content-wrapper">
          <img className="faq__mmb-arrow" src={mmb} alt="MMB Arrow" />
          <div>
            <h2 className="faq__heading">
              How to Subscribe
            </h2>
            <p className="faq__para">
              Signing up is quick and easy. Click the sign-up button, select your preferences,
              and start receiving news and offers on your selected services.
            </p>

            <p className="faq__para">
              Choose between:
            </p>
            <ul className="faq__list">
              <li>
                Utility offers
              </li>
              <li>
                Debt services
              </li>
              <li>
                Insurance deals
              </li>
              <li>
                Credit products
              </li>
              <li>
                Claims services
              </li>
              <li>
                Show me everything (we recommend this option)
              </li>
            </ul>
          </div>
        </div>

        <div className="faq__content-wrapper">
          <img className="faq__mmb-arrow" src={mmb} alt="MMB Arrow" />
          <div>
            <h2 className="faq__heading">
              How My Money Back Makes Profit
            </h2>
            <p className="faq__para">
              Our brand identity is built on trust and transparency. We’ll always be
              completely open with you, even when it comes to how we make money from
              our FREE service. Here’s how it works...
            </p>
            <p className="faq__para">
              We monitor and maintain agreements with our trusted partners, ensuring
              you receive email notifications each time relevant news and offers become available.
            </p>
            <p className="faq__para">
              In exchange for our mutually beneficial recommendation service, we receive referral
              fees.
            </p>
          </div>
        </div>

        <div className="faq__content-wrapper">
          <img className="faq__mmb-arrow" src={mmb} alt="MMB Arrow" />
          <div>
            <h2 className="faq__heading">
              Data Management
            </h2>
            <p className="faq__para">
              Your data is stored securely in accordance with industry regulations. We will
              always gain your consent before sharing your data with our trusted partners.
            </p>
            <p className="faq__para">
              For more details, please see our Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FAQ;
